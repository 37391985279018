<template lang="html">
<v-model-table model-name="AppointGroupList" :model-api="['models','Moph','MophAddons']"
  :model-api-search="appointGroupQuery"
  api-order-by="appointmentDateTime"
  model-title="รายการนัดหมายแบบกลุ่ม"
  :headers="headers" importable serverPagination>
  <template v-slot:toolBarItem>
    <v-date-field v-model="beginDate" label="ตั้งแต่วันที่" class="px-1" single-line hide-details clearable></v-date-field>
    <v-date-field v-model="endDate" label="ถึงวันที่" class="px-1" single-line hide-details clearable></v-date-field>
  </template>
  <template v-slot:modelForm="{data,rules,isCreating}">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field :rules="[rules.require(),rules.numeric('เลขบัตรประชาชนต้องเป็นตัวเลขเท่านั้น'),rules.length(13,'เลขบัตรประชาชนต้องมี 13 หลัก')]" v-model="data.cid" label="เลขบัตรประชาชน"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field :rules="[rules.require()]" v-model="data.name" label="ชื่อ-นามสกุล"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-date-time-field :rules="[rules.require()]" v-model="data.appointmentDateTime" append-icon="event" label="วัน-เวลานัดหมาย" onlyFuture></v-date-time-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="data.group" label="ชื่อกลุ่ม"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-master-field v-model="data.vaccine" groupKey="covid19VaccineSuggestion" label="ระบุชนิดวัคซีน"  clearable></v-master-field>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <template v-slot:item.appointmentDateTime="props">
    <v-label-datetime :date-time="props.item.appointmentDateTime" short-date-time></v-label-datetime>
  </template>
</v-model-table>
</template>

<script>
import helperDateTime from 'tantee-common/lib/helperDateTime'
import { isUndefined } from 'lodash'

export default {
  data: ()=>({
    beginDate: undefined,
    endDate: undefined,
    headers: [
      {
        text: 'วันที่-เวลา',
        value: 'appointmentDateTime',
        class: 'body-2'
      },
      {
        text: 'เลขประจำตัวประชาชน',
        value: 'cid',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'name',
        class: 'body-2'
      },
      {
        text: 'กลุ่ม',
        value: 'group',
        class: 'body-2'
      },
      {
        text: 'ระบุชนิดวัคซีน',
        value: 'vaccine',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ]
  }),
  computed: {
    appointGroupQuery() {
      let tmpQuery = undefined

      if (!isUndefined(this.beginDate) && this.beginDate!=null) {
        tmpQuery = {}
        if (!isUndefined(this.beginDate) && this.beginDate!=null) tmpQuery['whereDate#appointmentDateTime#>='] = this.beginDate 
        if (!isUndefined(this.endDate) && this.endDate!=null) tmpQuery['whereDate#appointmentDateTime#<='] = this.endDate 
        else tmpQuery['whereDate#appointmentDateTime#<='] = this.beginDate
      }

      return tmpQuery
    },
  },
  beforeMount() {
    this.beginDate = helperDateTime.today().format('YYYY-MM-DD')
  },
}
</script>

<style lang="css" scoped>
</style>
